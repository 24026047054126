import { useMemo, useState } from 'react'
import { filter, orderBy } from 'lodash'

export const useSearch = (data) => {
  const [valueSearch, setValueSearch] = useState('')

  const dataSearch = useMemo(() => {
    if (valueSearch === '') {
      return data
    }
    return filter(data, e => {
      const key = e?.key.toLowerCase() ?? ''
      const en = e?.value?.en?.toLowerCase() ?? ''
      const ru = e?.value?.ru?.toLowerCase() ?? ''
      const _valueSearch = valueSearch.toLowerCase() ?? ''
      return key.includes(_valueSearch) || en.includes(_valueSearch) || ru.includes(_valueSearch)
    })
  }, [data, valueSearch])

  const dataOrder = useMemo(() => {
    return orderBy(dataSearch, ['key'], 'asc')
  }, [dataSearch])

  return {
    valueSearch,
    setValueSearch,
    dataSearch: dataOrder
  }
}
