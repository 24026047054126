import React from 'react'
import {
  Box,
  Button, IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import { Form } from 'components/Form'
import { BlockSection } from 'components/BlockSection'
import { useCreateVersion } from './services/useCreateVersion'
import * as Yup from 'yup'
import { InputForm } from 'components/InputForm'
import { SmallAddIcon } from '@chakra-ui/icons'

const formSchema = Yup.object().shape({
  title: Yup.string().required('Заполните поле'),
  appVersionNumber: Yup.number().test(
    'is-decimal',
    'invalid decimal',
    value => value >= 0 && (value % 1 === 0)
  ),
})

export const Create = ({ isOpen, onClose, onRefresh, onOpen }) => {
  const { createVersion, isLoading } = useCreateVersion()

  const handleForm = async (values) => {
    await createVersion(values)
    onRefresh()
  }

  return (
    <>
      <IconButton
        isRound
        colorScheme="teal"
        aria-label="Left"
        icon={<SmallAddIcon />}
        onClick={onOpen}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Новая версия</ModalHeader>
          <ModalCloseButton mt={1} />
          <ModalBody>
            <Formik
              enableReinitialize
              validationSchema={formSchema}
              initialValues={{
                title: '',
                appVersionNumber: 0
              }}
              onSubmit={handleForm}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <BlockSection>
                    <Field
                      isHideError
                      autoFocus
                      name="title"
                      label="Наименование версии"
                      component={InputForm}
                      isDisabled={isLoading}
                    />
                    <Field
                      isHideError
                      type="number"
                      name="appVersionNumber"
                      label="Версия (целое число)"
                      placeholder="0"
                      component={InputForm}
                      isDisabled={isLoading}
                    />
                  </BlockSection>
                  <Box pt="30px" pb="20px">
                    <Button
                      width="100%"
                      type="submit"
                      colorScheme="teal"
                      isLoading={isLoading}
                    >
                      Создать
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
