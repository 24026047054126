import React from 'react'
import { CardWrapper } from 'components/CardWrapper'
import { Box, Button, Center, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import { Form } from 'components/Form'
import { BlockSection } from 'components/BlockSection'
import { InputForm } from 'components/InputForm'
import * as Yup from 'yup'
import { useLogin } from './services'
import { Icon } from '@chakra-ui/icons'
import { SiSublimetext } from 'react-icons/si'
import { useAuthentication } from 'components/useAuthentication'
import { useInfo } from '../../../components/useInfo';

const formSchema = Yup.object().shape({
  username: Yup.string().required('Заполните поле'),
  password: Yup.string().required('Заполните поле')
})

export const Login = () => {
  const { isLoading, request } = useLogin()
  const { getProfile } = useAuthentication()
  const { dataInfo } = useInfo()

  const handleForm = async (values) => {
    await request(values)
    await getProfile()
  }

  return (
    <>
      <CardWrapper>
        <Flex pt="40px" pb="50px" px="60px" justifyContent="center" flexDirection="column">
          <Stack spacing={2} pb="30px" textAlign="center" alignItems="center">
            <Stack spacing={1} alignItems="center">
              <Center
                boxSize="70px"
                backgroundColor="teal.200"
                borderRadius="full"
                bgGradient='linear(teal.200 0%, teal.300 50%)'
              >
                <Icon as={SiSublimetext} fontSize="2.3rem" color="white"/>
              </Center>
              <Heading>Строки</Heading>
            </Stack>
            <Text fontSize="0.9rem">{dataInfo?.adminPanelName}</Text>
          </Stack>
          <Formik
            enableReinitialize
            onSubmit={handleForm}
            validationSchema={formSchema}
            initialValues={{
              username: '',
              password: ''
            }}
          >
            {(props) => (
              <Form onSubmit={props.handleSubmit}>
                <BlockSection>
                  <Field
                    isHideError
                    size="lg"
                    name="username"
                    placeholder="Логин"
                    component={InputForm}
                    isDisabled={isLoading}
                  />
                  <Field
                    isHideError
                    type="password"
                    name="password"
                    size="lg"
                    placeholder="Пароль"
                    component={InputForm}
                    isDisabled={isLoading}
                    colorScheme="blackAlpha"
                  />
                  <Box pt="10px">
                    <Button
                      width="100%"
                      type="submit"
                      size="lg"
                      isLoading={isLoading}
                      colorScheme="teal"
                    >
                      Войти
                    </Button>
                  </Box>
                </BlockSection>
              </Form>
            )}
          </Formik>
        </Flex>
      </CardWrapper>
    </>
  )
}
