import React from 'react'
import { Box, Center, Fade, Flex, IconButton, useBoolean } from '@chakra-ui/react'
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import { Create } from './Create/Create';

export const SelectVersion =  React.forwardRef((
  {
    data = [],
    index,
    setIndex,
    isOpen,
    onClickVersion,
    onRefresh
  }, ref
) => {
  const [isOpenCreate, setIsOpenCreate] = useBoolean()

  const handleRefresh = () => {
    setIsOpenCreate.off()
    onRefresh()
  }

  const handleLeftClick = () => {
    if (index > 0) {
      setIndex(index - 1)
    }
  }

  const handleRightClick = () => {
    setIndex(index + 1)
  }

  return (
    <>
      <Flex
        ref={ref}
        flex={1}
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Center
          role="group"
          backgroundColor={isOpen ? 'whiteAlpha.100' : 'transparent'}
          transition="0.3s"
          height="100%"
          px="20px"
          borderTopRightRadius="10px"
          borderTopLeftRadius="10px"
        >
          <IconButton
            isRound
            colorScheme="gray"
            aria-label="Right"
            icon={<ArrowBackIcon />}
            isDisabled={(data?.length - 1 === index) || isOpen}
            onClick={handleRightClick}
          />
          <Flex
            cursor="pointer"
            justifyContent="center"
            minW="80px"
            fontSize="1.5rem"
            fontWeight="700"
            onClick={onClickVersion}
            transition="0.3s"
            _hover={{
              opacity: 0.7
            }}
          >
            {data && index !== -1 && data[index].appVersionNumber}
          </Flex>
          <Box position="relative">
            <IconButton
              isRound
              colorScheme="gray"
              aria-label="Left"
              icon={<ArrowForwardIcon />}
              isDisabled={index <= 0}
              onClick={handleLeftClick}
            />
            <Fade in={isOpen} unmountOnExit>
              <Box position="absolute" left={0} top={0}>
                <Create
                  isOpen={isOpenCreate}
                  onOpen={() => setIsOpenCreate.on()}
                  onClose={() => setIsOpenCreate.off()}
                  onRefresh={handleRefresh}
                />
              </Box>
            </Fade>
          </Box>
        </Center>
      </Flex>
    </>
  )
})
