import React, { useState, useEffect } from 'react'
import { Toolbar } from 'components/Toolbar'
import { Box, Collapse, Fade, Grid, useBoolean } from '@chakra-ui/react'
import { useFetchVersions } from './services'
import { useSearch } from './mappings';
import { InputSearch } from 'components/InputSearch'
import { Create } from './components/Create/Create'
import { useFetchStrings } from './services'
import { SelectVersion } from './components/SelectVersion'
import { StringsList } from './components/StringsList'
import { VersionsList } from './components/VersionsList'

export const Strings = () => {
  const refVersions = React.useRef()
  const { dataStrings, isLoadingFetchStrings, fetchStrings } = useFetchStrings()
  const { dataVersions, fetchVersions } = useFetchVersions()
  const [activeIndexVersion, setActiveIndexVersion] = useState(-1)
  const [isOpenVersions, setIsOpenVersions] = useBoolean()
  const [isOpenCreate, setIsOpenCreate] = useBoolean()
  const { valueSearch, setValueSearch, dataSearch } = useSearch(dataStrings)
  const [activeItem, setActiveItem] = useState({})
  const appVersionNumber = activeIndexVersion !== -1 && dataVersions[activeIndexVersion].appVersionNumber

/*  useOutsideClick({
    ref: refVersions,
    handler: (e) => console.log(e),
  })*/

  const handleItemClick = (e) => {
    setActiveItem(e)
    setIsOpenVersions.off()
    setIsOpenCreate.on()
  }

  const handleStringsRefresh = async () => {
    setIsOpenCreate.off()
    setActiveItem({})
    await fetchStrings(appVersionNumber)
  }

  const handleVersionsRefresh = async () => {
    await fetchVersions(appVersionNumber)
  }

  const handleCloseCreate = () => {
    setIsOpenCreate.off()
    setActiveItem({})
  }

  useEffect(() => {
    if (dataVersions) {
      setActiveIndexVersion(0)
    }
  }, [dataVersions])

  useEffect( () => {
    if (activeIndexVersion !== -1) {
      fetchStrings(appVersionNumber)
    }
  }, [activeIndexVersion, dataVersions])

  return (
    <>
      <Toolbar>
        <Grid templateColumns="1fr 1fr 1fr" flex={1} height="100%" alignItems="center">
          <Box width="100%" pr="20px">
            <InputSearch
              placeholder="Поиск..."
              name="search"
              value={valueSearch}
              onChange={setValueSearch}
              isLoading={isLoadingFetchStrings}
            />
          </Box>
          <SelectVersion
            ref={refVersions}
            data={dataVersions}
            index={activeIndexVersion}
            setIndex={setActiveIndexVersion}
            isOpen={isOpenVersions}
            onClickVersion={() => setIsOpenVersions.toggle()}
            onRefresh={handleVersionsRefresh}
          />
          <Fade in={!isOpenVersions} unmountOnExit>
            <Create
              data={activeItem}
              isOpen={isOpenCreate}
              onOpen={() => setIsOpenCreate.on()}
              onClose={handleCloseCreate}
              appVersionNumber={appVersionNumber}
              onRefresh={handleStringsRefresh}
            />
          </Fade>
        </Grid>
      </Toolbar>
      <Box position="relative">
        <Collapse
          in={isOpenVersions}
        >
          <VersionsList
            ref={refVersions}
            data={dataVersions}
            onItemClick={e => setActiveIndexVersion(e)}
            activeIndex={activeIndexVersion}
          />
        </Collapse>
        <StringsList
          data={dataSearch}
          isCollapse={isOpenVersions}
          onItemClick={handleItemClick}
        />
      </Box>
    </>
  )
}
