import useQuery from 'components/useQuery'
import { api } from 'api'
import { useEffect, useMemo } from 'react'
import { useToast } from '@chakra-ui/react'
import { isEmpty, orderBy } from 'lodash'

export const useFetchVersions = () => {
  const toast = useToast()
  const {
    isError,
    isLoading,
    response,
    request,
    error,
  } = useQuery(() => api.admin.fetchVersions())

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.errorText,
        status: 'error',
      })
    }
  }, [isError])

  useEffect( () => {
    request()
  }, [])

  const data = useMemo(() => {
    if (isEmpty(response?.list)) {
      return null
    }
    return orderBy(response?.list, ['appVersionNumber'], 'desc')
  }, [response?.list])

  return {
    fetchVersions: request,
    isLoadingFetchVersions: isLoading,
    dataVersions: data
  }
}
