import React, { useState } from 'react'
import {
  Box,
  Button,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import { Form } from 'components/Form'
import { BlockSection } from 'components/BlockSection'
import { useUpdateString } from './services/useUpdateString'
import * as Yup from 'yup'
import { InputForm } from 'components/InputForm'
import { TextAreaForm } from 'components/TextAreaForm'
import { isEmpty } from 'lodash'
import { CheckboxForm } from 'components/CheckboxForm'
import { FormLabel } from 'components/FormLabel'

const formSchema = Yup.object().shape({
  key: Yup.string().required('Заполните поле'),
  appVersionNumber: Yup.string().required('Заполните поле'),
})

export const Create = ({ data, isOpen, onClose, onRefresh, onOpen, appVersionNumber }) => {
  const { updateString, isLoading } = useUpdateString()
  const [activeVersionNumber, setActiveVersionNumber] = useState('selected')

  const handleClose = () => {
    setActiveVersionNumber('selected')
    onClose()
  }

  const handleForm = async (values) => {
    const dataRequest = {
      strings: [{
        ...values,
        ...!isEmpty(data) &&
        { appVersionNumber: (activeVersionNumber === 'key' || values.deleted) ? data?.appVersionNumber : appVersionNumber }
      }]
    }
    await updateString(dataRequest)
    onRefresh()
  }

  return (
    <>
      <Flex justifyContent="flex-end">
        <Button colorScheme="teal" onClick={onOpen}>
          Создать ключ
        </Button>
      </Flex>
      <Modal isOpen={isOpen} onClose={handleClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex alignItems="center">
              {isEmpty(data) ? 'Новый ключ' : 'Обновление ключа'}
              <Center
                width="fit-content"
                px="8px"
                height="20px"
                fontSize="0.9rem"
                backgroundColor="teal.200"
                fontWeight="700"
                transition="0.3s"
                borderRadius="full"
                color="gray.800"
                ml={3}
              >
                {appVersionNumber}
              </Center>
            </Flex>
          </ModalHeader>
          <ModalCloseButton mt={1} />
          <ModalBody>
            <Formik
              enableReinitialize
              validationSchema={formSchema}
              initialValues={{
                key: data?.key ?? '',
                value: {
                  ru: data?.value?.ru ?? '',
                  en: data?.value?.en ?? '',
                },
                appVersionNumber: appVersionNumber,
                deleted: data?.deleted ?? false,
              }}
              onSubmit={handleForm}
            >
              {(props) => {
                const isDelete = props.values.deleted
                return (
                  <Form onSubmit={props.handleSubmit}>
                    <BlockSection>
                      <Field
                        isHideError
                        autoFocus={isEmpty(data)}
                        name="key"
                        label="Ключ"
                        component={InputForm}
                        isReadOnly={!isEmpty(data)}
                        isDisabled={isLoading || isDelete}
                      />
                      {!isEmpty(data) && data?.appVersionNumber !== appVersionNumber && (
                        <RadioGroup
                          value={props.values.deleted ? 'key' : activeVersionNumber}
                          onChange={setActiveVersionNumber}
                          isDisabled={isLoading || isDelete}
                        >
                          <FormLabel>Версия</FormLabel>
                          <Stack spacing={5} direction="row">
                            <Radio colorScheme="teal" value="selected">
                              {appVersionNumber}
                            </Radio>
                            <Radio colorScheme="red" value="key">
                              {data?.appVersionNumber}
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                      <Field
                        autoFocus={!isEmpty(data)}
                        name="value.ru"
                        label="Русский"
                        component={TextAreaForm}
                        isDisabled={isLoading || isDelete}
                        color="purple.300"
                        fontFamily="Menlo, monospace"
                        fontSize="0.88rem"
                        minH="180px"
                      />
                      <Field
                        name="value.en"
                        label="Английский"
                        component={TextAreaForm}
                        isDisabled={isLoading || isDelete}
                        color="orange.300"
                        fontFamily="Menlo, monospace"
                        fontSize="0.88rem"
                        minH="180px"
                      />
                      {!isEmpty(data) && (
                        <Field name="deleted" component={CheckboxForm} label="Удалить" />
                      )}
                    </BlockSection>
                    <Box pt="30px" pb="20px">
                      <Button
                        width="100%"
                        type="submit"
                        colorScheme={isDelete ? 'red' : 'teal'}
                        isLoading={isLoading}
                      >
                        {isEmpty(data) ? 'Создать' : isDelete ? 'Удалить' : 'Обновить'}
                      </Button>
                    </Box>
                  </Form>
                )
              }}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
