import React from 'react'
import { TextArea } from '../TextArea'
import { Box, Stack } from '@chakra-ui/react';
import _ from 'lodash';

export const TextAreaForm = React.forwardRef(
  ({ field, form, sizeInput, isHideError, label = null, max, ...props }, ref) => {
    const handleChange = (e) => {
      if (max) {
        form.setFieldValue(field.name, e.target.value.substring(0, max))
      } else {
        form.setFieldValue(field.name, e.target.value)
      }
    }

    return (
      <TextArea
        ref={ref}
        name={field.name}
        label={label}
        isInvalid={_.get(form.errors, field.name) && _.get(form.touched, field.name)}
        value={field.value}
        error={_.get(form.errors, field.name)}
        onChange={handleChange}
        {...props}
      />
    )
  }
)
