import useQuery from 'components/useQuery'
import { api } from 'api'
import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'

export const useFetchStrings = () => {
  const toast = useToast()
  const {
    isError,
    isLoading,
    response,
    request,
    error,
  } = useQuery((data) => api.admin.fetchStrings(data))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.errorText,
        status: 'error',
      })
    }
  }, [isError])

  return {
    fetchStrings: request,
    isLoadingFetchStrings: isLoading,
    dataStrings: response?.list
  }
}
