import useQuery from 'components/useQuery'
import { api } from 'api'
import { useEffect } from 'react'
import { actions as info } from 'store/profile/info/store'
import { useDispatch, useSelector } from 'react-redux'

export const useInfo = () => {
  const dispatch = useDispatch()
  const { data } = useSelector(state => state.profile.info)

  const {
    isLoading,
    isSuccess,
    request,
    response,
  } = useQuery(() => api.admin.fetchInfo())

  useEffect(() => {
    if (isSuccess) {
      dispatch(info.update(response))
    }
  }, [isSuccess])

  return {
    getInfo: request,
    isLoadingInfo: isLoading,
    dataInfo: data
  }
}
