import useQuery from 'components/useQuery'
import { api } from 'api'
import { useEffect } from 'react'
import { actions as authentication } from 'store/profile/authentication/store'
import { useDispatch, useSelector } from 'react-redux'

export const useAuthentication = () => {
  const dispatch = useDispatch()
  const { isAuthenticated, data } = useSelector(state => state.profile.authentication)

  const {
    isLoading,
    isSuccess,
    request,
    response,
  } = useQuery(() => api.admin.getLogin())

  useEffect(() => {
    if (isSuccess) {
      dispatch(authentication.update(response))
    }
  }, [isSuccess])

  return {
    getProfile: request,
    isLoadingProfile: isLoading,
    isAuthenticated,
    dataProfile: data
  }
}
