import axios from 'axios'

const ENTITY = 'admin'

export const admin = Object.freeze({
  async fetchInfo(data) {
    const response = await axios.get(`/${ENTITY}/info`, data)
    return response.data
  },
  async login(data) {
    const response = await axios.post(`/${ENTITY}/login`, data)
    return response.data
  },
  async logout() {
    const response = await axios.get(`/${ENTITY}/logout`)
    return response.data
  },
  async getLogin() {
    const response = await axios.get(`/${ENTITY}/getLogin`)
    return response.data
  },
  async fetchStrings(appVersionNumber) {
    const response = await axios.get(`/${ENTITY}/strings?appVersionNumber=${appVersionNumber}`)
    return response.data
  },
  async updateStrings(data) {
    const response = await axios.post(`/${ENTITY}/strings`, data)
    return response.data
  },
  async fetchVersions() {
    const response = await axios.get(`/${ENTITY}/versions`)
    return response.data
  },
  async updateVersion(data) {
    const response = await axios.post(`/${ENTITY}/versions`, data)
    return response.data
  },
  async fetchUserList() {
    const response = await axios.get(`/${ENTITY}/user`)
    return response.data
  },
  async createUser(data) {
    const response = await axios.post(`/${ENTITY}/user`, data)
    return response.data
  },
  async updateUser(id, data) {
    const response = await axios.put(`/${ENTITY}/user/${id}`, data)
    return response.data
  },
})
