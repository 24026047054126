import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom'
import { Box } from '@chakra-ui/react'
import { Unauthorized } from 'pages/Unauthorized'
import { Authorized } from './Authorized'
import { useAuthentication } from 'components/useAuthentication'
import { RootLoading } from 'components/RootLoading'
import { useInfo } from 'components/useInfo'
import { Logout } from './Logout'

function App() {
  const { getProfile, isLoadingProfile, isAuthenticated } = useAuthentication()
  const { getInfo } = useInfo()

  useEffect(() => {
    getInfo()
    getProfile()
  }, [])

  return (
    <Box minW="1200px">
      {isLoadingProfile ? (
        <RootLoading/>
      ) : (
        <Switch>
          <Route path="/i">
            <Authorized/>
          </Route>
          <Route path={`/logout`} exact>
            <Logout/>
          </Route>
          <Route path="/">
            {isAuthenticated ? (
              <Redirect to={'/i'}/>
            ) : (
              <Unauthorized/>
            )}
          </Route>
        </Switch>
      )}
    </Box>
  );
}

export default App;
