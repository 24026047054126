import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Text,
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem, Flex, MenuDivider
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { useAuthentication } from '../../useAuthentication'

export const Profile = () => {
  const history = useHistory()
  const { dataProfile } = useAuthentication()

  const handleLogout = () => {
    history.push('/logout')
  }

  return (
    <Flex justifyContent="flex-end">
      <Box
        display="flex"
        role="group"
        justifyContent="space-between"
        transition="0.3s"
      >
        <Flex
          flexGrow={1}
          textAlign="right"
          alignItems="center"
        >
          <Text transition="0.3s" fontWeight="600">
            {dataProfile?.username}
          </Text>
        </Flex>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label={'Menu'}
            marginLeft="14px"
            icon={<ChevronDownIcon/>}
            variant="outline"
            size="md"
            isRound
          />
          <MenuList zIndex={30} fontSize="1rem">
            <MenuItem onClick={() => history.push('/i/users')}>Пользователи</MenuItem>
            <MenuDivider />
            <MenuItem onClick={handleLogout}>Выход</MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
};
