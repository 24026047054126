import React from 'react'
import { Box, Flex, Grid, Text } from '@chakra-ui/react'
import { FixedSizeList as List } from 'react-window'
import { Item } from './Item'
import { useWindowHeight } from 'components/useWindowHeight'

export const UserList = ({ data, onItemClick }) => {
  const windowHeight = useWindowHeight()
  const heightList = windowHeight - 202

  const Row = ({index, key, style}) => (
    <div key={key} style={style} className="post">
      <Item
        data={data}
        index={index}
        onClick={onItemClick}
      />
    </div>
  )

  return (
    <Box>
      <Grid
        templateColumns="40px minmax(200px, 1fr) minmax(300px, 1fr) minmax(300px, 1fr)"
        gap="20px"
        px="26px"
        fontSize="0.9rem"
        fontWeight="500"
        py="6px"
        backgroundColor="whiteAlpha.50"
      >
        <Box/>
        <Text>Логин</Text>
        <Text>Дата обновления</Text>
        <Text>Дата создания</Text>
      </Grid>
      <List
        width="100%"
        height={heightList}
        itemCount={data?.length}
        itemSize={64}
      >
        {Row}
      </List>
    </Box>
  )
}
