import React from 'react'
import { AuthorizedWrapper } from 'components/AuthorizedWrapper'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { useAuthentication } from 'components/useAuthentication'
import { PrivateRoute } from 'components/PrivateRoute'
import { Strings } from './Strings'
import { User } from './User'

export const Authorized = () => {
  const match = useRouteMatch()
  const { isAuthenticated } = useAuthentication()

  return (
    <AuthorizedWrapper>
      <Switch>
        <PrivateRoute
          path={`${match.url}/strings`}
        >
          <Strings/>
        </PrivateRoute>
        <PrivateRoute
          path={`${match.url}/users`}
        >
          <User/>
        </PrivateRoute>
        <Route path="/">
          <Redirect to={`${match.url}/strings`}/>
        </Route>
      </Switch>
    </AuthorizedWrapper>
  )
}

