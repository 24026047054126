import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@chakra-ui/icons'
import { IoIosApps } from 'react-icons/io'
import { Box, Center, Flex, Grid } from '@chakra-ui/react'

const Cell = ({ children, ...props }) => {
  return (
    <Box
      fontFamily="Menlo, monospace"
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
      fontSize="0.88rem"
      fontWeight="500"
      {...props}
    >
      {children}
    </Box>
  )
}

export const Item = ({ data, index, onClick }) => {
  return (
    <Grid
      role="group"
      px="26px"
      height="100%"
      templateColumns="40px minmax(200px, 1fr) minmax(300px, 1fr) minmax(300px, 1fr) 100px"
      gap="20px"
      alignItems="center"
      cursor="pointer"
      _hover={{
        backgroundColor: 'whiteAlpha.100'
      }}
      onClick={() => onClick(data[index])}
    >
      <Center
        boxSize="38px"
        backgroundColor="teal.300"
        borderRadius="full"
      >
        <Icon as={IoIosApps} fontSize="1.3rem" color="gray.700"/>
      </Center>
      <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
        {data[index].key}
      </Box>
      <Cell color="purple.300">
        {data[index].value?.ru}
      </Cell>
      <Cell color="orange.300">
        {data[index].value?.en}
      </Cell>
      <Flex justifyContent="flex-end">
        <Center
          width="fit-content"
          px="12px"
          height="20px"
          fontSize="0.9rem"
          backgroundColor="whiteAlpha.200"
          fontWeight="700"
          transition="0.3s"
          borderRadius="full"
        >
          {data[index].appVersionNumber}
        </Center>
      </Flex>
    </Grid>
  )
}
