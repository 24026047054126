import React from 'react'
import { Item } from './Item'
import { Box, Grid, Text } from '@chakra-ui/react'
import { FixedSizeList as List } from 'react-window'

export const VersionsList = React.forwardRef(({ data, activeIndex, onItemClick }, ref) => {
  const Row = ({ index, key, style }) => (
    <div key={key} style={style} className="post">
      <Item
        data={data}
        index={index}
        activeIndex={activeIndex}
        onClick={onItemClick}
      />
    </div>
  )

  return (
    <Box backgroundColor="whiteAlpha.100" ref={ref}>
      <List
        width="100%"
        height={300}
        itemCount={data?.length}
        itemSize={56}
      >
        {Row}
      </List>
    </Box>
  )
})
