import React, { useEffect, useState } from 'react'
import { FormControl, Icon, Input, InputGroup, InputLeftElement, InputRightElement, Spinner } from '@chakra-ui/react'
import { useDebounce } from 'use-debounce'
import { Search2Icon, SmallCloseIcon } from '@chakra-ui/icons'
import { FormLabel } from '../FormLabel'

export const InputSearch = (
  {
    onChange = null,
    defaultValue = '',
    value = '',
    placeholder,
    isLoading,
    label,
    name = 'search',
    hideIcon = false,
    size,
    ...props
  }) => {
  const [text, setText] = useState(defaultValue ? defaultValue : value);
  const [valueDebounce] = useDebounce(text, 200);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (value !== valueDebounce) {
      onChange && onChange(valueDebounce);
    }
  }, [valueDebounce]);

  useEffect(() => {
    if (value !== text) {
      setText(value);
    }
  }, [value])

  return (
    <FormControl>
      {label && (
        <FormLabel name={name}>
          {label}
        </FormLabel>
      )}
      <InputGroup width="100%" size={size}>
        <InputLeftElement>
          {isLoading ? (
            <Spinner color="gray.400" size="sm"/>
          ) : text ? (
            <Icon as={SmallCloseIcon} color="gray.500" onClick={() => setText('')}/>
          ) : (
            !hideIcon && (
              <Icon as={Search2Icon} color="gray.200"/>
            )
          )}
        </InputLeftElement>
        <Input
          id={name}
          value={text || ''}
          onChange={(e) => {
            setText(e.target.value);
          }}
          placeholder={placeholder}
          border={0}
          {...props}
        />
      </InputGroup>
    </FormControl>
  );
};
